import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// use createSlice to generate action type and action creator
const drawerSlice = createSlice({
  name: 'drawer',
  initialState: false,
  reducers: {
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      return action.payload; //cannot directly mutate state bc initialState is primitive
    },
  },
})

// export action creators
export const {
  toggleDrawer
} = drawerSlice.actions;

// export reducer
export default drawerSlice.reducer;