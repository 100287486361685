import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { researchArticles } from '../assets/research/researchArticles';
import {
  Article,
  ResearchArticles,
  ResearchState,
  SearchQuery,
} from '../types';

// helper function to search research articles
const searchArray = (
  arr: ResearchArticles,
  query: string
): ResearchArticles => {
  return arr.filter((article: Article): boolean => {
    if (article.title.toLowerCase().includes(query.toLowerCase())) {
      return true;
    } else if (
      article.description.toLowerCase().includes(query.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  });
};

const refineResults = (query: SearchQuery): ResearchArticles => {
  const queryArray = query.trim().split(/\s+/);
  let result = [...researchArticles];
  queryArray.forEach((query: string) => {
    result = searchArray(result, query);
  });
  return result;
};

const researchState: ResearchState = {
  displayArticles: researchArticles,
  searchQuery: '',
};

const researchSlice = createSlice({
  name: 'searchResearch',
  initialState: researchState,
  reducers: {
    searchResearch: (state, action: PayloadAction<string>): void => {
      state.searchQuery = action.payload; // update state of search queries
      state.displayArticles = refineResults(action.payload);
    },
  },
});

export const { searchResearch } = researchSlice.actions;

export default researchSlice.reducer;
