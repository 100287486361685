import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import drawerReducer from './features/drawerSlice';
import peopleReducer from './features/peopleSlice';
import researchReducer from './features/researchSlice';
import publicationsReducer from './features/publicationsSlice';

const reducer = {
  drawerReducer,
  peopleReducer,
  researchReducer,
  publicationsReducer,
}

const middleware = [...getDefaultMiddleware()];

export default configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production' // turn off chrome devtool for production build
});