// the article object below will be used to create a research card on /research

// import type of varible article from types.ts
import { Article } from '../../../types';

import thumbnailImage from './thumbnail.png';

const htmlContent = require('./article.html');

const article: Article = {
  title:
    'Design Principles for Multi-Axis, Large Force Magnitude Sensor Arrays for Use in Human and Robotic Applications',
  description: `Using new design principles and methodologies, we have developed a multi-axis, 
  large force detecting foot sensor for legged robots. This footpad sensor is intended for use 
  on the MIT Cheetah to provide a complete picture of the ground interaction forces that is a 
  necessity in enabling high-speed and dynamic ground locomotion.`,
  thumbnail: thumbnailImage,
  html: htmlContent,
  id: '3afa2455-8287-4ff3-8566-d9ac86f52d9d',
};

export default article;
