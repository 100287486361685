// import type from types.ts
import { Article } from '../../../types'; // don't remove this line

// import the thumbnail image
import thumbnailImage from './thumbnail.png'; // modify this import based on your image name

// import the new html file you wrote
const htmlContent = require('./article.html');

// edit the article object below with your article's info
const article: Article = {
  title: 'Archived Projects',
  description: `Research directions and projects that are no longer being actively explored.`,
  thumbnail: thumbnailImage,
  html: htmlContent,
  id: '6339b35c-124a-43da-8749-c2a777566065',
};

export default article;
