// import type from types.ts
import { Article } from '../../../types'; // don't remove this line

// import the thumbnail image
import thumbnailImage from './thumbnail.png'; // modify this import based on your image name

// import the new html file you wrote
const htmlContent = require('./article.html');

// edit the article object below with your article's info
const article: Article = {
  title: 'Sensor Design for Manipulation',
  description: 'For fast, dynamic manipulation tasks, the robot must be able to sense its environment quickly and accurately. This article discusses the design of sensors for manipulation tasks, and how to use them to improve the performance of a robot.',
  thumbnail: thumbnailImage,
  html: htmlContent,
  id: 'design-for-manipulation',
};

export default article;
