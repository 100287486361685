// import type from types.ts
import { Article } from '../../../types'; // don't remove this line

// import the thumbnail image
import thumbnailImage from './thumbnail.png'; // modify this import based on your image name

// import the new html file you wrote
const htmlContent = require('./article.html');

// edit the article object below with your article's info
const article: Article = {
  title: 'Reflexive Control for Manipulation',
  description: `The manipulation platform in the Biomimetic Robotics Lab was designed with the
  same principles of high bandwidth, proprioceptive control as its legged platforms. With force
  sensing arrays at the end effectors of the manipulators, we are investigating how reflexive
  behaviors can be designed to enable fast and reactive pick-and-place manipulation of objects.`,
  thumbnail: thumbnailImage,
  html: htmlContent,
  id: 'reflexive-control',
};

export default article;
