import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { publicationsList } from '../assets/publications/publicationsList';
import {
  DropdownPayload,
  PublicationsAppliedFilters,
  Publication,
  PublicationsList,
  PublicationsState,
  SearchQuery,
} from '../types';

// helper function for search bar
const searchArray = (
  arr: PublicationsList,
  query: string
): PublicationsList => {
  return arr.filter((publication: Publication): boolean => {
    for (const property in publication) {
      const textToSearch = publication[property];
      if (typeof textToSearch !== 'string') {
        continue;
      }
      if (
        property !== 'ID' &&
        textToSearch.toLowerCase().includes(query.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};

// helper function for filter
const filterArray = (
  arr: PublicationsList,
  filterName: string,
  selectedOption: string
): PublicationsList => {
  return arr.filter((publication: Publication): boolean => {
    // filterName could be either 'Year' or 'Publication Type'
    let property = filterName;
    if (
      filterName === 'Year' &&
      publication.hasOwnProperty('Year of Publication')
    ) {
      property = 'Year of Publication';
    } else if (
      filterName === 'Year' &&
      publication.hasOwnProperty('Year of Conference')
    ) {
      property = 'Year of Conference';
    }

    if (
      publication.hasOwnProperty(property) &&
      publication[property] === selectedOption
    ) {
      return true;
    } else {
      return false;
    }
  });
};

// helper function to sort
const sortArray = (arr: PublicationsList) => {
  let newArr = [...arr];
  return newArr.sort((a: Publication, b: Publication): number => {
    let yearTextA = '';
    let yearTextB = '';
    if (a.hasOwnProperty('Year of Publication')) {
      yearTextA = a['Year of Publication'] as string;
    } else if (a.hasOwnProperty('Year of Conference')) {
      yearTextA = a['Year of Conference'] as string;
    } else {
      console.error(
        `Year of publication/year of conference is not specified for article with ID "${a.ID}"`
      );
    }

    if (b.hasOwnProperty('Year of Publication')) {
      yearTextB = b['Year of Publication'] as string;
    } else if (b.hasOwnProperty('Year of Conference')) {
      yearTextB = b['Year of Conference'] as string;
    } else {
      console.error(
        `Year of publication/year of conference is not specified for article with ID "${b.ID}"`
      );
    }

    let sortCond: number;
    if (yearTextA < yearTextB) {
      sortCond = 1;
    } else if (yearTextA > yearTextB) {
      sortCond = -1;
    } else {
      let titleA = typeof a.Title === 'string' ? a.Title.toLowerCase() : '';
      let titleB = typeof b.Title === 'string' ? b.Title.toLowerCase() : '';
      sortCond = titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
    }
    return sortCond;
  });
};

// function to apply filters, search queries, and sort
const refineResults = (
  filters: PublicationsAppliedFilters,
  query: SearchQuery
): PublicationsList => {
  // apply filters to displayed list of publications
  const queryArray: string[] = query.trim().split(/\s+/);
  let result = [...publicationsList];
  for (let property in filters) {
    if (filters[property] !== 'All') {
      result = filterArray(result, property, filters[property]);
    }
  }

  //re-apply search query
  //if search query is an empty string or a string of space characters
  if (queryArray.length !== 1 || queryArray[0] !== '') {
    for (let i = 0; i < queryArray.length; i++) {
      result = searchArray(result, queryArray[i]);
    }
  }

  // sort
  result = sortArray(result);

  return result;
};

// create initial state
const publicationsState: PublicationsState = {
  displayList: sortArray(publicationsList),
  searchQuery: '',
  appliedFilters: { Year: 'All', 'Publication Type': 'All' },
};

const publicationsSlice = createSlice({
  name: 'publications',
  initialState: publicationsState,
  reducers: {
    searchPublications: (state, action: PayloadAction<string>): void => {
      state.searchQuery = action.payload; // update state of search queries
      state.displayList = refineResults(
        state.appliedFilters,
        state.searchQuery
      );
    },
    filterPublications: (
      state,
      { payload }: PayloadAction<DropdownPayload>
    ): void => {
      // if selected option is the same as the applied filter, nothing needs to be done
      if (
        state.appliedFilters[payload.dropdownName] === payload.selectedOption
      ) {
        return;
      } else {
        // update filter state
        state.appliedFilters[payload.dropdownName] = payload.selectedOption;
        state.displayList = refineResults(
          state.appliedFilters,
          state.searchQuery
        );
      }
    },
  },
});

export const {
  searchPublications,
  filterPublications,
} = publicationsSlice.actions;

export default publicationsSlice.reducer;
