// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-publications-publication-individual-tsx": () => import("./../../../src/components/publications/PublicationIndividual.tsx" /* webpackChunkName: "component---src-components-publications-publication-individual-tsx" */),
  "component---src-components-research-research-article-tsx": () => import("./../../../src/components/research/ResearchArticle.tsx" /* webpackChunkName: "component---src-components-research-research-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */)
}

