// the article object below will be used to create a research card on /research

// import type of varible article from types.ts
import { Article } from '../../../types';

import thumbnailImage from './leg_crossing_hardware_3frames.png';

const htmlContent = require('./article.html');

const article: Article = {
  title:
    'Tailoring Solution Accuracy for Fast Whole-body Model Predictive Control of Legged Robots',
  description: `We developped whole-body nonlinear model predictive controller. Instead of aiming for highly accurate
optimal solutions, we rely on low-accuracy solutions that can be obtained at high rates. This allows the MIT Humanoid to plan complex crossed-
leg and arm motions that help recover from significant disturbances.`,
  thumbnail: thumbnailImage,
  html: htmlContent,
  id: '3afa2455-8287-4ff3-8566-d9ac86f52d9d',
};

export default article;
