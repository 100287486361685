// import type from types.ts
import { Article } from '../../../types'; // don't remove this line

// import the thumbnail image
import thumbnailImage from './thumbnail.jpg'; // modify this import based on your image name

// import the new html file you wrote
const htmlContent = require('./article.html');

// edit the article object below with your article's info
const article: Article = {
  title: 'Optimal Actuator Design',
  description: `In designing a robot, the actuator's allowable mass and required output torque are 
  determined by the application. However, these requirements still leave a broad design space within 
  which to select motor size and gear ratio. We have developed an actuator design method that enables 
  force control in applications with highly dynamic environmental interactions. The method optimizes 
  the motor selection and gear ratio for high fidelity proprioceptive force control within given 
  actuator weight constraints. We implemented the method in the primary actuators of the MIT Cheetah.`,
  thumbnail: thumbnailImage,
  html: htmlContent,
  id: '1a9ba04b-d200-4743-b8fc-bf231f3231f0',
};

export default article;
