import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { peopleProfiles } from '../assets/people/peopleProfiles';
import {
  DropdownPayload,
  Profile,
  PeopleAppliedFilters,
  PeopleProfiles,
  PeopleState,
  SearchQuery,
} from '../types';

// helper function to search for a person
const searchArray = (arr: Profile[], query: string): Profile[] => {
  return arr.filter((person: Profile): boolean => {
    if (person.first.toLowerCase().includes(query.toLowerCase())) {
      return true;
    } else if (person.last.toLowerCase().includes(query.toLowerCase())) {
      return true;
    } else if (person.role.toLowerCase().includes(query.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  });
};

// helper function to sort people who are displayed by first or last name
const sortArray = (arr: Profile[], sortCondition: string): Profile[] => {
  let newArr = [...arr];
  let sortByFirst = true;
  let alphabetical = true;
  if (sortCondition === 'Last Name A-Z' || sortCondition === 'Last Name Z-A') {
    sortByFirst = false;
  }
  if (sortCondition === 'First Name Z-A' || sortCondition === 'Last Name Z-A') {
    alphabetical = false;
  }

  return newArr.sort((a: Profile, b: Profile): number => {
    let nameA = a.first.toLowerCase();
    let nameB = b.first.toLowerCase();
    if (!sortByFirst) {
      nameA = a.last.toLowerCase();
      nameB = b.last.toLowerCase();
    }
    // if name A comes first alphabetically
    if (nameA < nameB && alphabetical) {
      return -1;
    } else if (nameA < nameB && !alphabetical) {
      return 1;
    }
    // if name A comes second alphabetically
    if (nameA > nameB && alphabetical) {
      return 1;
    } else if (nameA > nameB && !alphabetical) {
      return -1;
    }
    // if names are equal
    return 0;
  });
};

// function to apply search query and sort condition
const refineResults = (
  filters: PeopleAppliedFilters,
  query: SearchQuery,
  sortCondition: string
): PeopleProfiles => {
  const queryArray = query.trim().split(/\s+/);
  let result = { ...peopleProfiles };
  // apply filters
  if (filters.membershipStatus === 'Current') {
    result.former = [];
  } else if (filters.membershipStatus === 'Former') {
    result.current = [];
  }

  // apply search queries
  //if search query is an empty string or a string of space characters
  if (queryArray.length > 1 || queryArray[0] !== '') {
    for (let i = 0; i < queryArray.length; i++) {
      result.current = searchArray(result.current, queryArray[i]);
      result.former = searchArray(result.former, queryArray[i]);
    }
  }

  // sort
  if (sortCondition !== 'None') {
    result.current = sortArray(result.current, sortCondition);
    result.former = sortArray(result.former, sortCondition);
  }

  return result;
};

// create initial state
const peopleState: PeopleState = {
  displayProfiles: peopleProfiles,
  appliedFilters: { membershipStatus: 'All' },
  appliedSort: 'None',
  searchQuery: '',
};

const peopleSlice = createSlice({
  name: 'searchPeople',
  initialState: peopleState,
  reducers: {
    processSearchQuery: (state, action: PayloadAction<string>): void => {
      state.searchQuery = action.payload;
      state.displayProfiles = refineResults(
        state.appliedFilters,
        state.searchQuery,
        state.appliedSort
      );
    },
    filterPeople: (
      state,
      { payload }: PayloadAction<DropdownPayload>
    ): void => {
      // if selected option is the same as the applied filter, nothing needs to be done
      if (
        state.appliedFilters[payload.dropdownName] === payload.selectedOption
      ) {
        return;
      } else {
        state.appliedFilters[payload.dropdownName] = payload.selectedOption;
        state.displayProfiles = refineResults(
          state.appliedFilters,
          state.searchQuery,
          state.appliedSort
        );
      }
    },
    sortPeople: (
      state,
      { payload }: PayloadAction<DropdownPayload>
    ): PeopleState => {
      if (state.appliedSort !== payload.selectedOption) {
        const newProfiles = refineResults(
          state.appliedFilters,
          state.searchQuery,
          payload.selectedOption
        );
        return {
          ...state,
          appliedSort: payload.selectedOption,
          displayProfiles: newProfiles,
        };
        // state.appliedSort = payload.selectedOption;
      } else {
        return state;
      }
    },
  },
});

export const {
  processSearchQuery,
  filterPeople,
  sortPeople,
} = peopleSlice.actions;

export default peopleSlice.reducer;
