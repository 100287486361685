// articles on the website will be listed in the order in which they are listed in the researchArticles array

import { ResearchArticles } from '../../types';

// import article objects
import design_principles_for_sensor_arrays from './06.design_principles_for_sensor_arrays/article';
import optimal_actuator_design from './05.optimal_actuator_design/article';
import archived_projects from './99.archived_projects/article';
import reinforcement_learning from './01.reinforcement_learning/article';
import optimal_control from './02.optimal_control/article';
import reflexive_manipulation from './03.reflexive_manipulation/article';
import sensor_design_manipulation from './04.sensor_design_manipulation/article';
import tailoring_solution_accuracy_NMPC from './07.tailoring_solution_accuracy_NMPC/article';

export const researchArticles: ResearchArticles = [
  // reinforcement_learning,
  // optimal_control,
  reflexive_manipulation,
  sensor_design_manipulation,
  tailoring_solution_accuracy_NMPC,
  design_principles_for_sensor_arrays,
  optimal_actuator_design,
  archived_projects,
];
